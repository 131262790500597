import React, { useContext } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { Box, SiteLink } from "../../helpers/styledComponents"
import { getSitePages } from "../../helpers/dataTransformers/sitePage"
import Logo from "./logo"
import NavContext from "../../helpers/context/nav"
import theme from "../../helpers/theme"

const Header = props => {
  const data = useStaticQuery(fragment)
  const sitePages = getSitePages(data.sitePages)
  const navConsumer = useContext(NavContext)
  const { setShowMobileNav, showMobileNav } = navConsumer

  return (
    <React.Fragment>
      {!showMobileNav && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          background={theme.colors.lychee}
          py="4"
          px={[4, 6]}
        >
          <Box flex="1">
            <Box
              width="25px"
              minHeight="20px"
              display={["block", "none"]}
              onClick={() => setShowMobileNav(true)}
            >
              <Img fluid={data.menu.childImageSharp.fluid} />
            </Box>
          </Box>
          <Box flex="1" display="flex" justifyContent="center">
            <Logo fluid={data.logo.siteLogo.asset.fluid} />
          </Box>
          <Box flex="1">
            <Box
              display={["none", "flex"]}
              justifyContent="flex-end"
              alignItems="center"
            >
              {sitePages.map((item, index) => {
                const { hidden, name, url } = item
                return (
                  !hidden && (
                    <Box pl={[4, 5]} key={`sitePageLink_${index}`}>
                      <SiteLink to={`${url.current}`}>{name}</SiteLink>
                    </Box>
                  )
                )
              })}
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

const fragment = graphql`
  query headerFragment {
    logo: sanitySiteSettings {
      siteName
      siteLogo {
        asset {
          fluid(maxWidth: 100) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    menu: file(relativePath: { eq: "menu.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sitePages: allSanitySitePage(
      sort: { order: ASC, fields: name }
      filter: { hidden: { ne: false } }
    ) {
      edges {
        node {
          url {
            current
          }
          name
          id
        }
      }
    }
  }
`

export default Header
