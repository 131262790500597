import React from "react"
import { Box, Body, Heading1 } from "../../helpers/styledComponents"

const SocialMedia = ({ socialMedia, users }) => {
  return (
    <Box flex="1" display="flex" flexDirection="column" alignItems="flex-start">
      <Box>
        <Heading1 fontSize={[2, 3]}>{socialMedia}</Heading1>
      </Box>
      {users.map((item, index) => {
        return (
          <Box
            pt={2}
            key={`socialMedia_${socialMedia}_${item.username}_${index}`}
          >
            <a href={item.url.current} target="_blank" rel="noopener">
              <Body fontSize="2" style={{ textDecoration: "underline" }}>
                {item.username}
              </Body>
            </a>
          </Box>
        )
      })}
    </Box>
  )
}

export default SocialMedia
