import React, { useContext } from "react"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"

import { Box } from "../../helpers/styledComponents"
import Footer from "./footer"
import Header from "./header"
import MobileNav from "./mobileNav"
import NavContext from "../../helpers/context/nav"
import theme from "../../helpers/theme"

const Base = props => {
  const navConsumer = useContext(NavContext)
  const { showMobileNav } = navConsumer
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <Header />
        {showMobileNav && <MobileNav />}
        {!showMobileNav && (
          <React.Fragment>
            <Box display="flex" justifyContent="center">
              <Box width="100%">
                <main>{props.children}</main>
              </Box>
            </Box>
            <Footer />
          </React.Fragment>
        )}
      </ThemeProvider>
    </Box>
  )
}

const globalStyles = `
  body {
    margin: 0;
  }

  body,
  input,
  textarea,
  select,
  button {
    font-family: 'Proxima Nova', sans-serif;
  a, a:hover {
    text-decoration: none;
    color: unset;
    box-shadow: none;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, p {
    font-size: 10px;
    line-height: 18px;
  }

  @media only screen and (min-width: 420px) {
    body, p {
      font-size: 12px;
      line-height: 24px;
    }
  }

  .b {
    font-weight: 500;
  }
`

export default Base
