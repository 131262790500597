import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Box } from "../../helpers/styledComponents"

const Logo = ({ fluid }) => {
  return (
    <Link to="/">
      <Box width={["80px", "100px"]} minHeight="20px">
        <Img fluid={fluid} />
      </Box>
    </Link>
  )
}

export default Logo
