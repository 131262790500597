import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Box, Body, Divider } from "../../helpers/styledComponents"
import Logo from "./logo"
import theme from "../../helpers/theme"
import SocialMedia from "./socialMedia"

const Footer = () => {
  const data = useStaticQuery(fragment)
  const { flora, lesley } = data
  return (
    <Box width="100%" background={theme.colors.white} py={[4, 5]} px={[4, 5]}>
      <Logo fluid={data.logo.siteLogo.asset.fluid} />
      <Box pt="4">
        <Body>project by lezzles & floflo</Body>
      </Box>
      <Box width="100%" py={[3, 4]}>
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        maxWidth={`${theme.maxWidth[0]}px`}
      >
        <SocialMedia
          socialMedia="Github"
          users={[flora.github, lesley.github]}
        />
        <SocialMedia
          socialMedia="Goodreads"
          users={[flora.goodreads, lesley.goodreads]}
        />
      </Box>
    </Box>
  )
}

const fragment = graphql`
  query footerFragment {
    logo: sanitySiteSettings {
      siteName
      siteLogo {
        asset {
          fluid(maxWidth: 100) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    lesley: sanityMember(details: { slug: { current: { eq: "lesley" } } }) {
      github {
        username
        url {
          current
        }
      }
      goodreads {
        url {
          current
        }
        username
      }
    }
    flora: sanityMember(details: { slug: { current: { eq: "flora" } } }) {
      github {
        username
        url {
          current
        }
      }
      goodreads {
        url {
          current
        }
        username
      }
    }
  }
`

export default Footer
