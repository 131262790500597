import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import theme from "./theme"

import {
  background,
  borderRadius,
  border,
  color,
  flexbox,
  colorStyle,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  layout,
  letterSpacing,
  lineHeight,
  position,
  space,
  size,
  textStyle,
  typography,
} from "styled-system"

export const Box = styled.div`
  ${background}
  ${borderRadius}
  ${border}
  ${color}
  ${colorStyle}
  ${flexbox}
  ${fontFamily}
  ${fontSize}
  ${fontStyle}
  ${fontWeight}
  ${fontWeight}
  ${grid}
  ${letterSpacing}
  ${layout}
  ${lineHeight}
  ${position}
  ${size}
  ${space}
  ${textStyle}
  ${typography}
`

export const Text = styled.p`
  ${background}
  ${color}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${layout}
  ${letterSpacing}
  ${lineHeight}
  ${position}
  ${space}
  ${typography}
  margin: 0;
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  cursor: ${props => props.pointer};
`

// Text

export const Heading1 = props => (
  <Text
    {...theme.textStyles.heading1}
    {...props}
    style={{ textTransform: "uppercase", ...props.style }}
  >
    {props.children}
  </Text>
)

export const Heading2 = props => (
  <Text
    {...theme.textStyles.heading2}
    {...props}
    style={{ textTransform: "uppercase", ...props.style }}
  >
    {props.children}
  </Text>
)

export const Heading3 = props => (
  <Text
    {...theme.textStyles.heading3}
    {...props}
    style={{ textTransform: "uppercase", ...props.style }}
  >
    {props.children}
  </Text>
)

export const Heading4 = props => (
  <Text
    {...theme.textStyles.heading4}
    {...props}
    style={{ textTransform: "uppercase", ...props.style }}
  >
    {props.children}
  </Text>
)

export const Body = props => (
  <Text {...theme.textStyles.body} {...props}>
    {props.children}
  </Text>
)

export const Divider = styled(Box)(props => ({
  height: "1px",
  backgroundColor: props.color || theme.colors.black,
  width: "100%",
}))

export const SiteLink = ({ to, children, style }) => {
  return (
    <Link to={to}>
      <Heading4 style={{ textDecoration: "underline", ...style }}>
        {children}
      </Heading4>
    </Link>
  )
}
