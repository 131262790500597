const breakpoints = ["520px", "719px", "999px", "1199"]

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const mediaQueries = breakpoints.map(
  bp => `@media screen and (minWidth: ${bp})`
)

const spaces = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const sizes = [16, 32, 64, 128, 256, 512, 600, 720]

const fontSizes = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48]

const maxWidth = [450, 600, 780, 960, 1080, 1300]

const imageWidth = [180, 240, 320, 480]

const imageHeight = [220, 310, 380, 580]

const responsiveFontSizes = {
  small: [0, 1],
  medium: [2, 3],
  large: [6, 7],
}

const colors = {
  black: "#000000",
  white: "#FFFFFF",
  lychee: "#F1EEE5",
  caramel: "#CEB48E",
  beige: "#F8EED7",
  mud: "#AB9679",
}

const fontFamilies = {
  optima: {
    name: "Optima",
    weights: [400],
  },
  proximaNova: {
    name: "Proxima Nova",
    weights: [400, 600],
  },
  termina: {
    name: "Termina",
    weights: [200, 400],
  },
}

const textStyles = {
  heading1: {
    fontFamily: fontFamilies.termina.name,
    fontSize: [8, 10],
    color: colors.black,
    fontWeight: 400,
    lineHeight: ["36px", "48px"],
  },
  heading2: {
    fontFamily: fontFamilies.termina.name,
    fontSize: [5, 7],
    color: colors.black,
    fontWeight: 200,
  },
  heading3: {
    fontFamily: fontFamilies.proximaNova.name,
    fontSize: [4, 5],
    color: colors.black,
    fontWeight: 600,
  },
  heading4: {
    fontFamily: fontFamilies.optima.name,
    fontSize: [2, 4],
  },
  body: {
    fontFamily: fontFamilies.proximaNova.name,
    fontSize: [3, 5],
    color: colors.black,
  },
}

const lightboxOptions = {
  showThumbnails: false,
}

const theme = {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
  imageHeight,
  imageWidth,
  lightboxOptions,
  maxWidth,
  mediaQueries,
  responsiveFontSizes,
  spaces,
  sizes,
  textStyles,
}

export default theme
