import React, { useContext } from "react"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"

import { Box, SiteLink } from "../../helpers/styledComponents"
import { getSitePages } from "../../helpers/dataTransformers/sitePage"
import NavContext from "../../helpers/context/nav"
import theme from "../../helpers/theme"

const MobileNav = () => {
  const data = useStaticQuery(fragment)
  const sitePages = getSitePages(data.sitePages)
  const navConsumer = useContext(NavContext)
  const { setShowMobileNav } = navConsumer

  return (
    <Box
      position="absolute"
      height="100vh"
      width="100vw"
      background={theme.colors.caramel}
      py="3"
    >
      <Box
        width="40px"
        height="40px"
        position="absolute"
        style={{
          transform: "translate(25px, 10px)",
        }}
        onClick={() => setShowMobileNav(false)}
      >
        <Img fluid={data.closeButton.childImageSharp.fluid} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <MobileNavLink
          setShowMobileNav={setShowMobileNav}
          url="/"
          name="Home"
        />
        {sitePages.map((item, index) => {
          return (
            <MobileNavLink
              key={`mobile_link_${item.url.current}_${index}`}
              setShowMobileNav={setShowMobileNav}
              url={item.url.current}
              name={item.name}
            />
          )
        })}
      </Box>
    </Box>
  )
}

const MobileNavLink = ({ setShowMobileNav, url, name }) => {
  return (
    <Box
      py="3"
      onClick={() => {
        setShowMobileNav(false)
      }}
      width="80%"
      display="flex"
      justifyContent="center"
    >
      <SiteLink
        style={{
          fontSize: theme.fontSizes[6],
          color: theme.colors.white,
          textDecoration: "none",
        }}
        to={url}
      >
        {name}
      </SiteLink>
    </Box>
  )
}

const fragment = graphql`
  query mobileNavFragment {
    closeButton: file(relativePath: { eq: "close.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sitePages: allSanitySitePage(
      sort: { order: ASC, fields: name }
      filter: { hidden: { ne: false } }
    ) {
      edges {
        node {
          url {
            current
          }
          name
          id
        }
      }
    }
  }
`

export default MobileNav
